<template>
  <div class="w-auto" v-if="loading">
    <clip-loader :loading="loading" color="primary"></clip-loader>
  </div>
  <div v-else class="aab-vie-content">
    <div v-if="currentSuscribeData.commentsProject">
      <div>
        <vs-popup
          fullscreen
          class="holamundo"
          :title="`Commentaires (${currentSuscribeData.commentsProject.length})`"
          :active.sync="popupActiveComment"
        >
          <div class="w-auto" v-if="loading">
            <clip-loader :loading="loading" color="primary"></clip-loader>
          </div>
          <div v-else>
            <div class="mb-6 mr-6 ml-6 mt-6">
              <div
                v-for="(comment, index) in currentSuscribeData.commentsProject"
                :key="index"
              >
                <vs-divider v-if="index > 0" />

                <div class="flex align-items-end justify-between">
                  <p class="pb-1 pt-1 text-black-70 text-primary">
                    {{
                      comment.user.person.firstname +
                      " " +
                      comment.user.person.lastname
                    }}
                  </p>
                  <div>
                    <span class="flex text-right">{{
                      dateElement(comment).textInfo
                    }}</span>
                    <span class="text-right">{{
                      dateElement(comment).textUp
                    }}</span>
                  </div>
                </div>

                <div class="mb-4">
                  <p
                    v-html="comment.content"
                    style="overflow-wrap: anywhere"
                  ></p>
                </div>

                <div class="mt-6 mb-3" style="display: flex; flex-direction: column">
                  <div
                    v-for="(file, index) in currentSuscribeData.commentsProject[index].filesname"
                    :key="index"
                    class="center"
                  > 
                    <div class="flex">
                      <span class="mr-2">{{ index + 1 }})</span>
                      <div v-if="file.filename && file.name" class="mb-6">
                        <b @click="downloadFile(file)" class="cursor-pointer">
                          {{ subString(file.name) }}
                        </b>
                      </div>
                      <div v-else class="mb-6">
                        <b>{{ subString(file) }}</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    currentSuscribeData.notice !== 'standardValidation' &&
                    currentSuscribeData.notice !== 'validationWithExtraPremium' &&
                    currentSuscribeData.notice !== 'rejection' 
                  "
                  style="font-size: x-small"
                  class="inline-flex"
                >
                  <div>
                    <vx-tooltip
                      color="primary"
                      text="Editer"
                      v-if="comment.user.id == user.id"
                    >
                      <div class="cursor-pointer ml-4">
                        <feather-icon
                          @click="
                            checkUpdateComment(
                              currentSuscribeData.commentsProject[index]
                            )
                          "
                          icon="Edit2Icon"
                          svgClasses="w-6 h-6"
                        ></feather-icon>
                      </div>
                    </vx-tooltip>
                  </div>

                  <div>
                    <vx-tooltip
                      color="primary"
                      text="Supprimer"
                      v-if="comment.user.id == user.id"
                    >
                      <div class="cursor-pointer ml-4">
                        <feather-icon
                          @click="
                            checkRemoveComment(
                              currentSuscribeData.commentsProject[index]
                            )
                          "
                          icon="Trash2Icon"
                          svgClasses="w-6 h-6"
                        ></feather-icon>
                      </div>
                    </vx-tooltip>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-6 mr-6 ml-6 mt-10">
              <div>
                <div class="con-exemple-prompt mb-8">
                  <span>
                    <strong>Ajouter un commentaire</strong>
                  </span>
                  <div class="w-full">
                    <quill-editor
                      v-model="formComment.message"
                      v-validate="'required'"
                      name="Commentaire"
                    ></quill-editor>

                    <span class="text-danger text-sm">{{
                      errors.first("commentaire")
                    }}</span>
                  </div>
                </div>

                <vs-col
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                  class="mb-6"
                >
                  <div
                    class="w-full"
                    style="
                      display: flex;
                      align-items: flex-end;
                      justify-content: space-between;
                    "
                  >
                    <div>
                      <div>
                        <div class="mb-4 ml-2">
                          <span>Fichier(s)</span>
                        </div>
                        <div>
                          <div uk-form-custom class="ebsi-upload-file">
                            <input
                              multiple
                              type="file"
                              :value="resetFilesUpload"
                              v-on:change="checkUploadFile"
                            />
                            <button
                              style="cursor: pointer"
                              class="uk-button uk-button-default"
                              type="button"
                              tabindex="-1"
                            >
                              Choisir vos fichiers
                            </button>
                          </div>
                        </div>
                      </div>

                      <span class="text-danger text-sm" v-show="formError"
                        >Erreur lors du chargement du fichier</span
                      >
                    </div>
                  </div>

                  <div
                    class="mt-6 mb-3"
                    style="display: flex; flex-direction: column"
                  >
                    <div
                      v-for="(file, index) in formComment.filesname"
                      :key="index"
                      class="center"
                    >
                      <div class="flex">
                        {{ index + 1 }})
                        <div v-if="file.filesname && file.name">
                          <b
                            @click="checkViewFile(file)"
                            class="cursor-pointer"
                          >
                            {{ subString(file.name) }}
                          </b>
                        </div>
                        <div v-else>
                          <b>{{ subString(file) }}</b>
                        </div>

                        <vx-tooltip color="primary" text="Supprimer">
                          <div
                            class="ml-8 cursor-pointer"
                            @click="removeFile(index)"
                          >
                            <feather-icon
                              icon="Trash2Icon"
                              svgClasses="w-6 h-6"
                            ></feather-icon>
                          </div>
                        </vx-tooltip>
                      </div>
                    </div>
                  </div>
                </vs-col>

                <div class="pt-2 pb-2">
                  <div class="inline-flex">
                    <div>
                      <vs-button
                        :disabled="loadingAddComment"
                        @click="addComment"
                        class="w-full sm:w-auto"
                        >Envoyer</vs-button
                      >
                      <vs-button
                        class="w-full sm:w-auto ml-4"
                        type="border"
                        @click="popupActiveComment = false"
                        >Annuler</vs-button
                      >
                    </div>
                    <div class="w-auto" v-if="loadingAddComment">
                      <clip-loader
                        :loading="loadingAddComment"
                        color="primary"
                      ></clip-loader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vs-popup>

        <vs-popup
          class="holamundo"
          title="Fichier à télécharger"
          :active.sync="popupActiveDownload"
        >
          <div class="mt-6 mb-3" style="display: flex; flex-direction: column">
            <div
              v-for="(file, index) in fileToDownload.filesname"
              :key="index"
              class="center"
            >
              <div class="flex">
                <span class="mr-2">{{ index + 1 }})</span>
                <div v-if="file.filename && file.name" class="mb-6">
                  <b @click="checkViewFile(file)" class="cursor-pointer">
                    {{ subString(file.name) }}
                  </b>
                </div>
                <div v-else class="mb-6">
                  <b>{{ subString(file) }}</b>
                </div>
              </div>
            </div>
          </div>
        </vs-popup>

        <vs-popup
          class="holamundo"
          title="Modifier un commentaire"
          :active.sync="popupUpdatedComment"
        >
          <div class="m-4">
            <div class="clearfix">
              <div>
                <vs-row vs-w="12">
                  <vs-col
                    class="pr-4 mb-4"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="12"
                    vs-sm="12"
                    vs-xs="12"
                  >
                    <div class="w-full">
                      <span>
                        <strong>Commentaire</strong>
                      </span>

                      <quill-editor
                        v-model="formUpdated.content"
                        v-validate="'required'"
                        name="Commentaire"
                      ></quill-editor>
                      <span class="text-danger text-sm">
                        {{ errors.first("commentaire") }}
                      </span>
                    </div>
                  </vs-col>

                  <vs-col
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="12"
                    vs-sm="12"
                    vs-xs="12"
                    class="mb-6"
                  >
                    <div
                      class="w-full"
                      style="
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                      "
                    >
                      <div>
                        <div>
                          <div class="mb-4 ml-2">
                            <span>Fichier(s)</span>
                          </div>
                          <div>
                            <div uk-form-custom class="ebsi-upload-file">
                              <input
                                multiple
                                type="file"
                                :value="resetFilesUpload"
                                v-on:change="checkUploadFileUpdated"
                              />
                              <button
                                style="cursor: pointer"
                                class="uk-button uk-button-default"
                                type="button"
                                tabindex="-1"
                              >
                                Choisir vos fichiers
                              </button>
                            </div>
                          </div>
                        </div>

                        <span class="text-danger text-sm" v-show="formError"
                          >Erreur lors du chargement du fichier</span
                        >
                      </div>
                    </div>

                    <div
                      class="mt-6 mb-3"
                      style="display: flex; flex-direction: column"
                    >
                      <div
                        v-for="(file, index) in formUpdated.filesname"
                        :key="index"
                        class="center"
                      >
                        <div class="flex">
                          {{ index + 1 }})
                          <div v-if="file.filename && file.name">
                            <b
                              @click="checkViewFile(file)"
                              class="cursor-pointer"
                              >{{ subString(file.name) }}</b
                            >
                          </div>
                          <div v-else>
                            <b>{{ subString(file) }}</b>
                          </div>

                          <vx-tooltip color="primary" text="Supprimer">
                            <div
                              class="ml-8 cursor-pointer"
                              @click="removeFileUpdated(index)"
                            >
                              <feather-icon
                                icon="Trash2Icon"
                                svgClasses="w-6 h-6"
                              ></feather-icon>
                            </div>
                          </vx-tooltip>
                        </div>
                      </div>
                      <div class="mt-2">
                        <p class="text-danger">* Champ obligatoire</p>
                      </div>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </div>
            <div class="mt-2">
              <div class="inline-flex">
                <div>
                  <vs-button
                    class="w-full sm:w-auto"
                    :disabled="loadingUpdatedComment"
                    @click="UpdatedComment"
                    >Valider</vs-button
                  >
                  <vs-button
                    class="w-full sm:w-auto ml-4"
                    type="border"
                    @click="popupUpdatedComment = false"
                    >Annuler</vs-button
                  >
                </div>
                <div class="ml-2">
                  <clip-loader
                    :loading="loadingUpdatedComment || loadingDownloadFile"
                    color="primary"
                  ></clip-loader>
                </div>
              </div>
            </div>
          </div>
        </vs-popup>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  name: "aab-vie-subscribe-comment",
  props: {
    crrModuleName: String,
    isClo: Boolean,
    item: Number,
  },
  components: {
    quillEditor,
    ClipLoader,
  },
  data() {
    return {
      fileData: [],
      crrID: [],
      fileDataUpUpdated: [],
      resetFilesUpload: "",
      formData: [],
      formError: false,
      loadingAddComment: false,
      loadingRemoveComment: false,
      loadingUpdatedComment: false,
      popupUpdatedComment: false,
      loadingDownloadFile: false,
      popupActiveComment: false,
      fileDataUp: [],
      formDataUpdated: [],
      error: true,
      errorMsg: "",
      formUpdated: {
        message: "",
        filesname: [],
      },
      formComment: {
        filesname: [],
        message: "",
      },
      fileToDownload: [],
      loading: false,
      popupActiveDownload: false,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("suscribe", ["currentSuscribeData"]),
  },
  methods: {
    ...mapActions("suscribe", [
      "applyPutComment",
      "applyAddComment",
      "applyRemoveComment",
      "applyGetProject",
    ]),

    ...mapActions("general", ["applyUploadFile"]),

    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    checkViewFile(item) {
      window.open(this.showFileData(item.filename), "_blank");
    },
    downloadFile(data) {
      this.loadingDownloadFile = true;
      try {
        var FileSaver = require("file-saver");
        FileSaver.saveAs(
          `${this.$config.serverURL}/downloads/${data.filename}`,
          `${data.name}`
        );
        this.loadingDownloadFile = false;
      } catch (error) {
        this.loadingDownloadFile = false;
      }

      //this.popupActiveDownload = true;
    },
    checkUpdateComment(item) {
      this.formUpdated.filesname = [];

      this.formUpdated = {
        ...item,
      };
      this.filesListBeforeUpdated = this.formUpdated.filesname;
      this.crrID = item.id;
      this.popupUpdatedComment = true;
    },

    async UpdatedComment() {
      this.loadingUpdatedComment = true;

      if (this.formUpdated.filesname && this.formUpdated.filesname.length > 0) {
        await this.uploadedFilesUp()
          .then((resUp) => {
            setTimeout(async () => {
              this.acceptAlert(
                mixin.methods.message("subscription", "file").success
              );

              this.fetchApplyUpdatedComment();
            }, 1000);
          })
          .catch((err) => {
            this.acceptAlert(
              mixin.methods.message("subscription", "file").error
            );

            this.fetchApplyUpdatedComment();
          });
      } else {
        this.fetchApplyUpdatedComment();
      }
    },
    async fetchApplyUpdatedComment() {
      this.loadingUpdatedComment = true;
      let data = {
        commentId: this.formUpdated.id,
        ...this.formUpdated,
        filesname: [...this.filesListBeforeUpdated, ...this.fileDataUpUpdated],
      };
      try {
        await this.applyPutComment(data);
        this.acceptAlert(
          mixin.methods.message("subscription", "commentUpdate").success
        );
        this.formUpdated = {
          message: "",
          filesname: [],
        };
        this.formDataUpdated = [];
        this.displayFileName = false;
        this.fileDataUpUpdated = [];
        this.filesListBeforeUpdated = [];
        this.loadingUpdatedComment = false;
        this.popupUpdatedComment = false;
      } catch (err) {
        this.loadingUpdatedComment = false;
        this.error = true;
        this.errorMsg = "";
      }
    },
    showFileToDownload(data) {
      this.fileToDownload = {
        filesname: data.filesname,
      };
      this.popupActiveDownload = true;
    },

    dateElement(data) {
      return mixin.methods.dateElement(data);
    },

    ///// fichier////////

    removeFile(data) {
      this.formData.splice(data, 1);
      this.formComment.filesname.splice(data, 1);
    },
    removeFileUpdated(data) {
      this.formDataUpdated.splice(data, 1);
      this.formUpdated.filesname.splice(data, 1);
    },
    subString(data) {
      if (data.length > 40) {
        return `${data.substring(0, 40)} ...`;
      } else {
        return data;
      }
    },
    checkUploadFile(evt) {
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formData.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();
        formDataTmp.append("uri", evt.target.files[i]);
        this.formData[formDataLength + 1] = formDataTmp;
        this.formError = false;
        allFilesChecked.push(evt.target.files[i].name);
        formDataLength++;
      }
      if (this.formComment.filesname) {
        this.formComment.filesname = [
          ...this.formComment.filesname,
          ...allFilesChecked,
        ];
      } else {
        this.formComment.filesname = [...allFilesChecked];
      }
    },
    checkUploadFileUpdated(evt) {
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formDataUpdated.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();
        formDataTmp.append("uri", evt.target.files[i]);
        this.formDataUpdated[formDataLength + 1] = formDataTmp;
        this.formError = false;
        allFilesChecked.push(evt.target.files[i].name);
        formDataLength++;
      }
      if (this.formUpdated.filesname) {
        this.formUpdated.filesname = [
          ...this.formUpdated.filesname,
          ...allFilesChecked,
        ];
      } else {
        this.formUpdated.filesname = [...allFilesChecked];
      }
    },
    async uploadedFiles() {
      let _this = this;
      let productPromises = [];
      this.formData.forEach(function (ele, index) {
        productPromises.push(
          (async function (ele) {
            const res = await _this.applyUploadFile(ele);
            _this.fileDataUp.push({
              filename: res.data[0].uploadedName,
              name: res.data[0].filename,
            });
          })(ele)
        );
      });
      await Promise.all(productPromises);
    },
    async uploadedFilesUp() {
      let _this = this;
      let productPromisesUp = [];
      this.formDataUpdated.forEach(function (ele, index) {
        productPromisesUp.push(
          (async function (ele) {
            const resUp = await _this.applyUploadFile(ele);
            _this.fileDataUpUpdated.push({
              filename: resUp.data[0].uploadedName,
              name: resUp.data[0].filename,
            });
          })(ele)
        );
      });
      await Promise.all(productPromisesUp);
    },
    async addComment() {
      this.loadingAddComment = true;

      if (this.formComment.filesname && this.formComment.filesname.length > 0) {
        await this.uploadedFiles()
          .then((res) => {
            this.acceptAlert(
              mixin.methods.message("subscription", "file").success
            );

            this.fetchApplyAddComment();
          })
          .catch((err) => {
            this.acceptAlert(
              mixin.methods.message("subscription", "file").error
            );

            this.fetchApplyAddComment();
          });
      } else {
        this.fetchApplyAddComment();
      }
    },
    async fetchApplyAddComment() {
      this.loadingAddComment = true;
      try {
        let data = {
          projectId: this.item,
          content: this.formComment.message,
          filesname: this.fileDataUp,
        };
        await this.applyAddComment(data);

        this.acceptAlert(
          mixin.methods.message("subscription", "comment").success
        );

        this.formComment = {
          filesname: [],
          message: "",
        };

        this.formData = [];
        this.fileDataUp = [];
        this.loadingAddComment = false;
      } catch (err) {
        this.loadingAddComment = false;
        this.popupActiveComment = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    mixin.methods.message("subscription", "comment").error
                  );
                  break;
              }
            });
          }
        }
      }
    },
    async getSouscription(item) {
      this.loading = true;

      try {
        await this.applyGetProject({ projectId: item, isComments: true });

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";
      }
    },
    showFileData(data) {
      return `${this.$config.serverURL}/downloads/${data}`;
    },
    checkRemoveComment(comm) {
      this.crrID = comm.id;
      this.$vs.dialog({
        type: "confirm",
        color: "primary",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer ce commentaire?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRemoveComment,
      });
    }, 
    async fetchApplyRemoveComment() {
      this.loadingRemoveComment = true;
      try {
        let data = {
          ids: [this.crrID],
        };
        await this.applyRemoveComment(data);
        this.acceptAlert(
          mixin.methods.message("subscription", "commentDelete").success
        );
        this.loadingRemoveComment = false;

        this.woIsAction = "";
      } catch (err) {
        this.loadingRemoveComment = false;
        this.woIsAction = "";
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "bordereauNotFound":
                  this.errorMsg = "Commentaire inexistant";
                  this.acceptAlert(
                    "danger",
                    "Suppression",
                    "Ce commentaire n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("subscription", "commentDelete").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
  },
  watch: {
    popupUpdatedComment() {
      this.$emit("onListen", !this.popupUpdatedComment);
    },
    popupActiveDownload() {
      this.$emit("onListen", !this.popupActiveDownload);
    },
    popupActiveComment() {
      if (!this.popupActiveComment) {
        this.$emit("onListen", false);
      }
    },
    isClo() {
      this.popupActiveComment = this.isClo;
    },
    item() {
      this.getSouscription(this.item);
    },
  },
};
</script>
